<template>
  <div class="columns is-mobile is-multiline">
    <div class="column is-12">
      <p class="title is-6 has-text-dark">
        <span class="is-valigned">Price position indicator</span>
      </p>
    </div>
    <div class="column is-12">
      <div class="columns is-mobile has-text-primary">
        <div class="column">
          <p
            class="has-text-weight-light"
            data-test-id="companion-price-position"
          >
            {{ pricePosition.text }}
          </p>
        </div>
        <div class="column is-narrow">
          <p class="has-text-weight-light">
            <span
              :class="`is-${pricePosition.colour}`"
              class="tag is-medium has-text-weight-semibold"
            >
              {{ pricePosition.tag }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanionPricePosition',
  props: {
    pricePosition: {
      type: Object,
      required: true
    },
    isSold: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
